import React from "react";
import { Button, ListGroupItem } from "react-bootstrap";
import {
  BsFileEarmarkRuled,
  BsMailbox,
  BsPencil,
  BsQuestionCircle,
  BsShop,
  BsTruck,
} from "react-icons/bs";
import AddressBlock from "./AddressBlock";

const AddressListItem = ({
  item,
  className,
  multiline = false,
  to, // as Link
  onClick, // as Button
  ...rest
}) => {
  const Icon =
    item.type === "shop"
      ? BsShop
      : item.type === "delivery"
      ? BsTruck
      : item.type === "postal"
      ? BsMailbox
      : item.type === "invoice"
      ? BsFileEarmarkRuled
      : BsQuestionCircle;

  const linkProps = to ? { to } : {};
  const btnProps = onClick ? { onClick } : {};

  return (
    <ListGroupItem
      key={item?.name + item?.type + item?.postalCode + item?.houseNumber}
      action={to || onClick}
      className={`hover-parent mb-2 d-flex justify-content-start align-items-center${
        className ? ` ${className}` : ""
      }`}
      {...linkProps}
      {...btnProps}
      {...rest}
    >
      <Icon
        className={`fs-5 text-muted${
          multiline ? " ms-1 me-3 my-2 align-self-start" : " me-2 ms-n1"
        }`}
        title={item?.type}
      />
      <AddressBlock address={item} multiline={multiline} />

      {to ? (
        <Button
          as="span"
          variant="link"
          className={`hover-opacity ${
            multiline ? " align-self-end me-n1" : " my-n2 me-n3"
          }`}
          title="Edit"
        >
          <BsPencil />
        </Button>
      ) : null}
    </ListGroupItem>
  );
};

export default AddressListItem;
