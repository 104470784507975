import React from "react";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../../utils/useFetch";
import { config } from "../../../config";
import {
  Badge,
  Button,
  Card,
  Col,
  ListGroup,
  Row,
  Stack,
} from "react-bootstrap";
import WithLoaderAndError from "../../ui/WithLoaderAndError";
import LabeledItem from "../../ui/LabeledItem";
import ImgBox from "../../ui/ImgBox";
import { FaStore } from "react-icons/fa6";
import { BsX } from "react-icons/bs";
import AddressListItem from "./AddressListItem";

// "id": 101277,
// "code": "1233221",
// "mdbCustomerId": null,
// "parentId": null,

// "logoFileId": null,

// "language": "NL",

// "active": true,
// "isTestCustomer": true,
// "onHold": null,
//   "onHoldBy": null,
//   "onHoldDescription": "Test",

// "name": "Test Customer 10",
//   "marketingName": null,
//   "username": null,

// "internalLabels": [],
// "externalLabels": [],

// "description": "tertgtrtg",
// "openingTimes": null,

// "fulfilmentPartnerId": 1,
// "customerGroupDto": null,
// "accountManager": "f31c887c-f927-e211-be9e-0023aeff5648",
// "externalAccountManager": null,
// "salesManager": "5663e44e-02e0-e311-8eb1-0023aeff5648",
// "projectManager": "5663e44e-02e0-e311-8eb1-0023aeff5648",
// "projectManagerDto": null,
// "marketingConsultant": null,

// "addresses": [
//   {
//     "type": "shop",
//     "name": "Main address",
//     "street": "Milky Way",
//     "houseNumber": "1128",
//     "suffix": "K",
//     "postalCode": "4218",
//     "city": "Croctopus",
//     "province": "Nowhere",
//     "country": "Everland"
//   },
// ],

// "contactDetails": []

const CustomerViewInner = ({
  id,
  name,
  description,
  code,
  active,
  language,
  marketingName,
  parentId,
  customerGroupDto,
  mdbCustomerId,
  isTestCustomer,
  accountManager,
  onHold,
  onHoldBy,
  onHoldDescription,
  openingTimes,
  username,
  salesManager,
  projectManager,
  logoFileId,
  addresses = [],
  internalLabels = [],
  externalLabels = [],
  projectManagerDto,
  externalAccountManager,
  marketingConsultant,
  contactDetails = [],
  fulfilmentPartnerId,
  mpLogo,
}) => {
  return (
    <div>
      <LabeledItem
        label="name"
        name={<h2 className="mb-0 mt-n1">{name}</h2>}
        fixLabel
        matchInputPadding={false}
      />
      <Stack
        direction="horizontal"
        gap={3}
        className="font-monospace text-muted"
      >
        <span>id {id}</span>
        <span>code {code}</span>
        <span>mdbCustomerId {mdbCustomerId}</span>
        <span>parentId {parentId}</span>
        <span>language {language}</span>
      </Stack>

      <Stack
        direction="horizontal"
        gap={3}
        className="font-monospace text-muted"
      >
        <span>{active ? "active" : "inactive"}</span>
        <span>{isTestCustomer ? "isTestCustomer" : "notTestCustomer"}</span>
        <span>{onHold ? "onHold" : "notOnHold"}</span>
      </Stack>

      <Row>
        <Col>
          <ImgBox
            // imgId={logoFileId}
            imgId={mpLogo}
            width={200}
            // ratio={false}
            padding={10}
            className="fade-in delay img-box-aspect-ratio-65"
            icon={FaStore}
            imgBg="white"
            imgColor="rgba(var(--bs-px-dark-rgb), .2)"
            resizeMode="contain"
          />
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>

      <Stack direction="vertical" gap={3}>
        <LabeledItem
          label="marketingName"
          name={marketingName}
          fixLabel
          matchInputPadding={false}
        />
        <LabeledItem
          label="username"
          name={username}
          fixLabel
          matchInputPadding={false}
        />

        <LabeledItem
          label="description"
          name={description}
          fixLabel
          matchInputPadding={false}
        />
        <LabeledItem
          label="openingTimes"
          name={openingTimes}
          fixLabel
          matchInputPadding={false}
        />
      </Stack>

      <Stack
        direction="horizontal"
        gap={3}
        className="font-monospace text-muted"
      >
        {internalLabels.map(({ id, name, description, sequence }) => (
          <span key={id} title={description}>
            {name}
          </span>
        ))}
      </Stack>
      <Stack
        direction="horizontal"
        gap={3}
        className="font-monospace text-muted"
      >
        {externalLabels.map(({ id, name, description, sequence }) => (
          <span key={id} title={description}>
            {name}
          </span>
        ))}
      </Stack>

      <Row>
        <Col>
          <h4>
            Addresses
            <Badge
              pill
              bg={addresses?.length ? "success-tint-10" : "secondary-tint-50"}
              as="sup"
              className="ms-2 fs-45 align-baseline"
            >
              {addresses?.length}
            </Badge>
          </h4>

          <ListGroup variant="flush">
            {addresses.map((item, i) => (
              <AddressListItem key={item?.name + i} item={item} />
            ))}
          </ListGroup>
        </Col>
        <Col>
          <h4>
            Contact details
            <Badge
              pill
              bg={
                contactDetails?.length ? "success-tint-10" : "secondary-tint-50"
              }
              as="sup"
              className="ms-2 fs-45 align-baseline"
            >
              {contactDetails?.length}
            </Badge>
          </h4>
          <ListGroup variant="flush">
            {contactDetails.map((item, i) => (
              <AddressListItem key={item?.name + i} item={item} />
            ))}
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
};

const CustomerView = () => {
  const { customerId: id } = useParams();

  const dataUrl = `${config.api.phoenix}/customers/${id}`;

  const { data, error } = useFetch({
    url: dataUrl,
    customerId: id,
  });

  const {
    data: mp,
    // error: mpError,
    // mutate: refreshMp,
  } = useFetch({
    url: `${config.api.phoenix}/marketingprofiles/${id}`,
    customerId: id,
  });

  // const {
  //   data: contactDetails,
  //   // error: contactDetailsError
  // } = useFetch({
  //   url: `${config.api.phoenix}/customers/${id}/contactdetails`,
  //   customerId: id,
  // });

  // console.log(Object.keys(data));
  // console.log(Object.keys(contactDetails));
  // console.log(mp);
  // console.log(Object.keys(mp));
  // console.log(contactDetails);

  return (
    <Card className="card-main border-0 border-start" body>
      <Button as={Link} to="..">
        <BsX />
      </Button>
      <WithLoaderAndError isLoading={!data && !error} error={error} fullpage>
        <CustomerViewInner {...data} mpLogo={mp?.images?.logo?.fileId} />
      </WithLoaderAndError>
    </Card>
  );
};

export default CustomerView;
