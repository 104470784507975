import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

const OutletRow = ({ paramName = "itemId", children }) => {
  const [isFinished, setIsFinished] = useState(true);
  const trDuration = 350;
  const params = useParams();
  const itemId = params[paramName];

  useEffect(() => {
    setIsFinished(false);
    setTimeout(() => {
      setIsFinished(true);
    }, trDuration);
  }, [itemId]);

  return (
    <div
      className="outlet-row"
      style={{ "--transition-duration": trDuration / 1000 + "s" }}
    >
      <div
        className={`outlet-col ${itemId ? "isCollapsed" : "isActive"} ${
          isFinished ? "finished" : ""
        }`}
      >
        {children}
      </div>
      <div
        className={`outlet-col ${itemId ? "isActive" : "isCollapsed"} ${
          isFinished ? "finished" : ""
        }`}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default OutletRow;
